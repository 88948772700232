/* Customize your main colors in :root variables */

@import '~antd/dist/antd.dark.css';

:root {
  --main-background-color: #000000;
  --card-background-color: #000000;
  --card-background-lighter-color: #000000;
  --main-text-color: #f7f6f4;
  --title-text-color: #ff00b2;
}

body {
  background: url('https://liminalitycorp.com/wp-content/uploads/2022/08/LMNLT_OUTPOST-BKGD-4k-02-scaled.jpg');
  background-size: cover; 
  /*background-color: var(--main-background-color);*/

  margin: 0;
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #303030;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

h1,
h2,
p {
  color: var(--main-text-color);
}

h3 {
  font-weight: normal;
}

/* Candy Shop styling overrides */
.css-1wewypr {
  color: black;
}

.css-1ago99h {
  color: black;
}

.candy-powered-by {
  display:none!important;
}

.candy-powered-by-buy {
  display:none!important;
}

.candy-connection-button {
  background: linear-gradient(90deg, #67c8fc 0%, #8c7afc 48.44%, #d765ff 100%)!important;
}

.candy-connection-button:hover {
  background:linear-gradient(180deg, #d765ff 100%, #8c7afc 48.44%, #67c8fc 0%)!important;
}

.candy-button {
  background: linear-gradient(90deg, #d803e7 0%, #eb7afc 48.44%, #d765ff 100%)!important;
  width: 180px!important;
}
.candy-button:hover {
  background: linear-gradient(45deg, #d765ff 100%, #eb7afc 48.44%, #cc41d5 0%)!important;
  width: 180px!important;
}

.candy-modal-close-btn .close:before, .candy-modal-close-btn .close:after {
  background-color: red!important;
}
.candy-modal-close-btn .close {
  opacity: 1!important;
}
.candy-input-price {
  border: 2px solid #d765ff!important;
}

.candy-nft-attribute {
  color: #effb69b6 !important;
  border: 2px solid #e8fc7aa2!important;
  background-color: transparent!important;
}

.candy-button {
  border-radius: 5px!important;
}
.candy-auction-modal-countdown .candy-countdown {
  background: linear-gradient(90deg, #effb69b6 0%, #e8fc7aa2 48.44%, #d6e103cb 100%)!important;
}

.candy-value a {
  color: #f522f2!important;
}

input, button, select, optgroup, textarea {
  background-color: rgb(18, 13, 24)!important;
}

.candy-card-info .candy-card-footer {
  color: #fefefed9!important;
}
.candy-card-info .name
{
  color: #fefefed9!important;
}
.candy-title,.candy-price,.candy-input-label,.candy-input-price,.candy-stat
{
  color: #fefefed9!important;
}


.candy-modal .candy-modal-content {
  background-color: rgba(18, 13, 24, 0.97)!important;
}
.candy-card-info {
  background-color: rgb(18, 13, 24)!important;
}

.candy-auction-modal-prompt {
  display:none!important;
}

.fEjKty   {
  border-radius: 5px!important;
}

.candy-countdown {
  border-radius: 5px!important;
}

.candy-auction-modal-notice {
  border-radius: 5px!important;
}

.candy-nft-attribute {
  border-radius: 5px!important;
}

.candy-edition-filter > div.active, .candy-edition-filter > div:hover {
  border-color: #d803e7;
  text-shadow: 1px 0 0 currentColor;
}
.candy-edition-filter > div {
  border-radius: 5px!important;
}
.ant-modal-header {
  background: #120d18!important;
}

/*hover zoom selection auctions*/
.candy-container-list {
  margin-top: 30px!important;

}
.candy-card {
  transition: transform .2s; /* Animation */
}
.candy-card:hover {
  transform: scale(1.03); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


.candy-modal .candy-modal-content {
  color: white;
}

.candy-sell-modal-button {
  padding: 5px !important;
}

.candy-order-info {
  background-color: rgb(18, 13, 24)!important;
  
}
.candy-cancel-modal button.candy-cancel-modal-button {
  padding: 0px 0px;
}
.candy-order .candy-order-name, .candy-order .candy-order-price {
  color: #fff;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
}


.candy-search input {
  border: 1px solid #bdbdbd!important;
}

/*SELECT CURRENCY MENU*/
.hGgDWg { 
  width: 150px;
  margin-right: 25px;
  color: #fff;
  cursor: pointer;
  font-family: 'Inter','Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 35px!important;
  border-radius: 5px!important;
  list-style-type: none;
  border: 2px solid #fff!important;
  }
  .hGgDWg .MuiList-root {
    margin-top: 2px!important;
    width: 150px;
  line-height: 35px!important;
  border-radius: 5px!important;
  list-style-type: none;

}
 .MuiPaper-root  {
  background-color: rgba(18, 13, 24, 0.97) !important;
 }
 .MuiListItem-gutters {
  padding-left: 25px;
}
 .hGgDWg a  {
  color: #d803e7 !important;
  font-family: 'Inter','Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
 }


  .candy-orders-filter > .candy-filter ul li.selected {
    font-weight: 500;
    background-color: transparent!important;
    color: #d803e7!important;
}
   .gUUMM .candy-filter li:hover {
  color: #d765ff!important;
}

.candy-filter-selected-name {
  font-size: 12px;
  color: #d803e7;
  font-weight: bold;
  padding: 0 8px;
  border: 1px solid #d803e7;
  border-radius: 4px;
  width: fit-content;
  margin-bottom: 6px;
}

/**navbar align**/
.sc-breuTD .kDAqRO {
  float: right;
}